@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  text-align: center;
  margin: 1em;
}

.logo img {
  width: 150px;
}

nav {
  margin: 2em auto 0;
  max-width: 600px;
}

nav ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  font-size: 1.3em;
  color: white;
  font-weight: 700;
  text-decoration: none;
}

@media screen and (min-width: 1020px) {
  header {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    align-items: center;
  }

  nav {
    margin: 0;
  }

  nav ul {
    margin: 0;
    padding: 0;
    display: flex;
  }

  nav ul li {
    list-style: none;
    padding: 0.5em 2em;
  }

  nav ul li a {
    font-size: 1.375em;
    color: white;
    font-weight: 700;
    text-decoration: none;
    text-shadow: 1px 1px 1px #000;
  }

  nav ul li a:hover {
    color: black;
    text-shadow: 1px 1px 1px #fff;
  }

  .highlight {
    border: 3px solid rgba(34, 193, 195, 1);
    border-radius: 50px;
  }
}

main {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.hero {
  text-align: center;
}

.hero h1 {
  font-family: "Permanent Marker", sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
}

.hero p {
  font-size: 1.2em;
  font-weight: 600;
  max-width: 500px;
  line-height: 1.8em;
  margin: 3em auto 1.5em 0;
  padding: 0 1rem;
}

.hero p:before {
  content: "";
  display: block;
  width: 120px;
  border: 2px solid rgba(253, 187, 45, 1);
  margin: 1em auto;
}

.hero a {
  padding: 1em 3em;
  text-decoration: none;
}

.hero a img {
  width: 200px;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.web-promo {
  background-color: #373435;
  width: 200px;
  height: 60px;
  border-radius: 0.5rem;
  color: white;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem;
}

.web-promo > span:first-of-type {
  font-size: 10px;
  font-weight: normal;
}

figure {
  text-align: center;
}

figure img {
  margin: 3em auto 0;
  width: 350px;
}

@media screen and (min-width: 1020px) {
  main {
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }

  .hero {
    text-align: center;
  }

  .hero h1 {
    font-size: 4em;
    text-shadow: 1px 1px 1px #000;
  }

  .hero p {
    font-size: 1.3em;
    padding: 0;
    margin: 0 0 1.5em 0;
    text-shadow: 1px 1px 1px #000;
  }

  .hero a img {
    background: 200px;
  }

  .figure img {
    width: 300px;
    margin: 0;
  }
}

body {
  font-family: "Source Sans Pro", sans-serif;
  background: rgb(34, 193, 195);
  background: -webkit-gradient(
      linear,
      left bottom, left top,
      from(rgba(34, 193, 195, 1)),
      to(rgba(253, 187, 45, 1))
    )
    no-repeat right top rgb(34, 193, 195);
  background: linear-gradient(
      0deg,
      rgba(34, 193, 195, 1) 0%,
      rgba(253, 187, 45, 1) 100%
    )
    no-repeat right top rgb(34, 193, 195);
  color: white;
}

.section-article {
  background: rgb(0, 0, 0, 0.4);
  border-radius: 50px;
  padding: 1em 2em;
  text-align: left;
  font-size: 1.3em;
  width: 75%;
  margin: 0 auto;
}

.copyright {
  float: right;
  padding: 1em;
}
.copyright a {
  color: white;
  text-decoration: none;
  text-shadow: 1px 1px 1px #000;
}

.copyright a:hover {
  color: black;
  text-shadow: 1px 1px 1px #fff;
}

